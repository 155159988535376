import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
    render() {
        return (
            <section>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                            <img src={require(`../../assets/images/about/vecteezy_cleaning-service-concept-cleaning-service-worker-cleaning_11086696.jpg`)} alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">
                            <div>
                                <span className="badge badge-primary-soft p-2">
                                    {/*<i className="la la-exclamation ic-3x rotation" />*/}
                                </span>
                                <h2 className="mt-3">About Us</h2>
                                <p style={{ textAlign: 'justify' }}>
                                    <b>RENENT LTD</b> Commenced facility maintenance services in Lagos in 1994 and now serves clients spread across five geo-political regions in Nigeria [South west, North west, North central, South east and South south. All operational functions are coordinated from Lagos and Abuja offices.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Our objective is to optimize the functionality of the built-up environment to enable our clients focus on the core functions of their respective organizations.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Our cleaning arm has been a pace-setter in the janitorial sector of the facilities management industry in Nigeria in the last two decades. Off-shore operations opened in the United Arab Emirates in January 2012, as Al Nafees Technical Services LLC, providing building maintenance services in Dubai and adjoining emirates.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Syndicated FM projects of more diverse nature in Nigeria, are handled through our subsidiary; Tripod Facility Management Ltd. The group is client focused, capable of delivering services of global standards and perpetually seeks better ways of meeting clients needs.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Our calling remains the humble attempt to satisfy and possibly surpass the needs and expectations of our clients. In the quest for excellence, our service delivery is driven by innovation. As we invite you to take advantage of our capabilities and service offerings, we look forward to an opportunity to meet with your representatives for a cost effective review of your facilities maintenance needs.
                                </p>
                            </div>
                            <div className="d-flex flex-wrap justify-content-start">
                            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
                            {/*    <div className="d-flex align-items-center">*/}
                            {/*    <div className="badge-primary-soft rounded p-1">*/}
                            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
                            {/*        <polyline points="20 6 9 17 4 12" />*/}
                            {/*        </svg>*/}
                            {/*    </div>*/}
                            {/*    <p className="mb-0 ml-3">Fully Responsive</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
                            {/*    <div className="d-flex align-items-center">*/}
                            {/*    <div className="badge-primary-soft rounded p-1">*/}
                            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
                            {/*        <polyline points="20 6 9 17 4 12" />*/}
                            {/*        </svg>*/}
                            {/*    </div>*/}
                            {/*    <p className="mb-0 ml-3">Based On Bootstrap 4</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
                            {/*    <div className="d-flex align-items-center">*/}
                            {/*    <div className="badge-primary-soft rounded p-1">*/}
                            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
                            {/*        <polyline points="20 6 9 17 4 12" />*/}
                            {/*        </svg>*/}
                            {/*    </div>*/}
                            {/*    <p className="mb-0 ml-3">Built with Sass</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="mb-3 mr-4 ml-lg-0 mr-lg-4">*/}
                            {/*    <div className="d-flex align-items-center">*/}
                            {/*    <div className="badge-primary-soft rounded p-1">*/}
                            {/*        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">*/}
                            {/*        <polyline points="20 6 9 17 4 12" />*/}
                            {/*        </svg>*/}
                            {/*    </div>*/}
                            {/*    <p className="mb-0 ml-3">SVG Icon</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            </div>
                            {/*<Link to="/" className="btn btn-outline-primary mt-5">*/}
                            {/*    Learn More Here...*/}
                            {/*</Link>*/}
                        </div>
                        </div>
                    </div>
                    </section>
        );
    }
}

export default About;
