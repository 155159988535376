import React, { Component } from 'react';
import About from '../../widgets/index/about';
import Services from '../../widgets/index/services';
import Work from '../../widgets/index/work';
import Feature from '../../widgets/featurebox/feature';
import Testimonial1 from '../../widgets/testimonial/testimonial1';
import Herosection from '../../widgets/herosection/herosection';
import Blog4 from '../../widgets/blog/blog4';
import Worktab from "../../widgets/featuare/worktab";
import OwlCarousel from "react-owl-carousel";

class Index extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <section>
                    <div className="container">
                        <Herosection />
                    </div>
                </section>
                <div className="page-content">
                    {/*feature start*/}
                    <Feature />
                    {/*feature end*/}
                    {/*about start*/}
                    <About />
                    {/*about end*/}
                    <section>
                        <div className="container">
                            <h2 className="text-center">
                                Services
                            </h2>
                            <Worktab />
                        </div>
                    </section>
                    {/*services start*/}
                    {/*<Services />*/}
                    {/*services end*/}
                    {/*screenshots start*/}
                    <section>
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                                    <div className="mb-5"> <span className="badge badge-primary-soft p-2">
                    {/*<i className="la la-file-image-o ic-3x rotation" />*/}
                  </span>
                                        <h2 className="mt-3">Our Memberships and Affiliations</h2>
                                        {/*<p className="lead mb-0">We</p>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <OwlCarousel
                                        className="owl-carousel"
                                        dotData={false}
                                        items={3}
                                        dots={false}
                                        autoplay={true}
                                    >
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/affiliates/affiliate-1.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/affiliates/affiliate-2.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/affiliates/affiliate-3.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/affiliates/affiliate-4.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/affiliates/affiliate-5.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/affiliates/affiliate-6.png`)} alt="" />
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                                    <div className="mb-5"> <span className="badge badge-primary-soft p-2">
                    {/*<i className="la la-file-image-o ic-3x rotation" />*/}
                  </span>
                                        <h2 className="mt-3">Our Clients</h2>
                                        <p className="lead mb-0">Just a few of our notable clients...</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <OwlCarousel
                                        className="owl-carousel justify-content-center"
                                        dotData={false}
                                        items={3}
                                        dots={false}
                                        autoplay={true}
                                    >
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/coca_cola_logo_PNG2.png`)} width="768px" alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/client-1.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/client-2.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/client-3.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/client-4.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/client-6.png`)} alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-fluid" src={require(`../../assets/images/clients/client-7.png`)} alt="" />
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*screenshots end*/}
                    {/*work start*/}
                    <Work />
                    {/*work end*/}
                </div>
            </div>
        );
    }
}

export default Index;
