import React, { Component } from 'react';
import { DatePicker } from "antd"
const FORM_API_GATEWAY = 'https://formspree.io/f/meqnbjgl';

const ContactForm1 = () => {
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [formData, setFormData] = React.useState({});

    const handleChange = React.useCallback((attribute) => (event) => {
        console.log(event.target.value);
        setFormData({
            ...formData,
            [attribute]: event.target.value
        })
    }, [formData]);

    const handleMultiSelectChange = React.useCallback((attr) => (e) => {
        const val = Array.from(e.target.selectedOptions, option => option.value);
        setFormData({
            ...formData,
            [attr]: val
        })
    }, [formData]);

    const onDateSelected = React.useCallback((attr) => (dateValue) => {
        const dateString = dateValue.toString();
        setFormData({
            ...formData,
            [attr]: dateString
        })
    }, [formData]);

    const handleSubmit = React.useCallback((e, f) => {
        e.preventDefault();
        console.log(formData);
        try {
            fetch(FORM_API_GATEWAY, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(formData),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }).then(() => {
                alert('form has been submitted')
            });
        } catch (error) {
            // handle server errors
            alert('Error submitting contact form here');
        }
    }, [selectedDate, formData])

    return (
        <div className="row justify-content-center text-center">
            <div className="col-12 col-lg-10">
                {/* TODO: Change the onSubmit action to be handled in JS*/}
                <form id="contact-form" className="row" onSubmit={handleSubmit}>
                    <div className="messages" />
                    <div className="form-group col-md-6">
                        <input id="form_name" type="text" name="firstName" className="form-control" placeholder="First Name*" required="required" data-error="Name is required." onChange={handleChange('firstName')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-md-6">
                        <input id="form_name1" type="text" name="lastName" className="form-control" placeholder="Last Name*" required="required" data-error="Name is required." onChange={handleChange('lastName')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-md-6">
                        <input id="form_email" type="email" name="email" className="form-control" placeholder="Email*" required="required" data-error="Valid email is required." onChange={handleChange('email')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-md-6">
                        <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Phone*" required="required" data-error="Phone is required" onChange={handleChange('phone')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-md-12">
                        <h3> Property Information </h3>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Number of Rooms</label>
                        <input id="form_bedroom" type="number" name="bedroomCount" className="form-control" placeholder="Number of Rooms" required="required" data-error="Number is required"  min={1} onChange={handleChange('roomCount')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Number of Bathrooms</label>
                        <input id="form_bathroom" type="number" name="bathroomCount" className="form-control" placeholder="Number of Bathrooms" required="required" data-error="Number is required"  min={1} onChange={handleChange('bathroomCount')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-md-12">
                        <label><b>Service Requested</b> - Please select the the multiple services you may require</label>
                        <select className="form-control" multiple={true} name="serviceRequested" onChange={handleMultiSelectChange('serviceRequested')}>
                            <option>- Please Select</option>
                            <option>Residential Cleaning</option>
                            <option>Janitorial/Office Cleaning</option>
                            <option>Housekeeping & Guest House Management</option>
                            <option>Gardening</option>
                            <option>Painting</option>
                            <option>Swimming pool Cleaning</option>
                            <option>Factory</option>
                            <option>Hospital</option>
                            <option>Generator servicing</option>
                            <option>Post-Construction Cleaning</option>
                            <option>Carpet and Upholstery Cleaning</option>
                            <option>Floor Maintenance & Restoration</option>
                            <option>Fumigation & Pest Control</option>
                            <option>Recruitment, Training & Consultancy</option>
                            <option>Sterilization</option>
                            <option>Facade Cleaning</option>
                            <option>Other</option>

                        </select>
                    </div>
                    <div className="form-group col-md-6">
                        <label><b>Service Requested</b>-When would you like us to come</label>
                        <DatePicker
                            onChange={onDateSelected('dateRequested')}
                            status="warning"
                            style={{
                                width: '100%',
                                height: '48px'
                            }}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label><b>Time: &nbsp;</b>From...</label>
                        <div className="col-md-12">
                            <select className="form-control" name="fromTime" onChange={handleChange('fromTime')}>
                                <option>- Please Select Time</option>
                                <option value="8:00 AM" >8:00 AM</option>
                                <option value="9:00 AM">9:00 AM</option>
                                <option value="10:00 AM">10:00 AM</option>
                                <option value="11:00 AM">11:00 AM</option>
                                <option value="12:00 NN">12:00 NN</option>
                                <option value="13:00 AM">13:00 PM</option>
                                <option value="14:00 AM">14:00 PM</option>
                                <option value="15:00 AM">15:00 PM</option>
                                <option value="16:00 AM">16:00 PM</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label>To...</label>
                        <div className="col-md-12">
                            <select className="form-control" name="toTime" onChange={handleChange('toTime')} required={true}>
                                <option>- Please Select Time</option>
                                <option value="8:00 AM">8:00 AM</option>
                                <option value="9:00 AM">9:00 AM</option>
                                <option value="10:00 AM">10:00 AM</option>
                                <option value="11:00 AM">11:00 AM</option>
                                <option value="12:00 NN">12:00 NN</option>
                                <option value="13:00 AM">13:00 PM</option>
                                <option value="14:00 AM">14:00 PM</option>
                                <option value="15:00 AM">15:00 PM</option>
                                <option value="16:00 AM">16:00 PM</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-md-12" style={{textAlign: 'left'}}>
                        <label><b>How Often?</b><br/>Scheduling is flexible</label>
                        <select className="form-control" name="scheduling" onChange={handleChange('scheduling')}>
                            <option>Please select Schedule</option>
                            <option value="One Time Service" selected="">One Time Service</option>
                            <option value="Weekly">Recurring</option>
                        </select>
                    </div>
                    <div className="form-group col-md-12">
                        <textarea id="form_message" name="message" className="form-control" placeholder="Message" rows={4}  data-error="Please,leave us a message." defaultValue={""} onChange={handleChange('message')} />
                        <div className="help-block with-errors" />
                    </div>
                    <div className="col-md-12 text-center mt-4">
                        <button className="btn btn-primary" type="submit"><span>Book Now!</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default ContactForm1;
