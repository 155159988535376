import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Clientlogo from '../common/Clientlogo';

class Herosection extends Component {
    render() {
        return (

            <div className="row align-items-center">
                <div className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}
                    <img src={require(`../../assets/images/hero/tools-new.png`)} className="img-fluid" alt="..." />
                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                    {/* Heading */}
                    <h5 className="badge badge-primary-soft font-w-6">Since 1994</h5>
                    <h1 className="display-4">
                        Building Maintenance Services <span className="text-primary">Anytime & Anywhere</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-muted">We offer all kinds of building maintenance services</p>
                    <Link to="/contact-us" className="btn btn-outline-primary">
                        Get a Quote in Minutes
                            </Link>
                    {/*<Clientlogo logoitems={4} margintop={10} />*/}
                </div>
            </div>

        );
    }
}

export default Herosection;
