import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Work extends Component {
    render() {
        return (
            <section className="pt-0">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-6 order-lg-2 mb-6 mb-lg-0">
                        <img src={require(`../../assets/images/about/02.png`)} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5 order-lg-1">
                        <div>
                            <h2 className="mt-3">Value Proposition</h2>
                            <p className="lead mb-5 text-justify">To optimize the functionality of the built-up entity and its immediate environs at all times within realistic budget limits. The peculiar needs, convenience and satisfaction of each client shall remain the focus of our attention.</p>
                            <p className="lead mb-5 text-justify">
                                At Renent, our main focus is to make the environment as comfortable and as hygienic a place as it can be.
                            </p>
                            <p className="lead mb-5 text-justify">
                                We deliver services with a passion that aims at achieving excellent results. The pillars upon which these standards are built include:
                                <ul className="navbar-nav mb-0">
                                    <li className="mb-3 nav-item"><i className="la la-check" />Sourcing and coordinating skillful and resourceful technical service providers</li>
                                    <li className="mb-3 nav-item"><i className="la la-check" />Ensuring the optimal functioning of systems and equipment</li>
                                    <li className="mb-3 nav-item"><i className="la la-check" />Design and implementation of quality control procedures for all parts of the facility</li>
                                    <li className="mb-3 nav-item"><i className="la la-check" />Affordable pricing of services</li>
                                    <li className="mb-3 nav-item"><i className="la la-check" />Quick response to service needs</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;
