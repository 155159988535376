import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faClock } from "@fortawesome/free-regular-svg-icons";
import { faMosquito, faPersonSwimming } from "@fortawesome/free-solid-svg-icons";
import { ICON_COLOUR } from "../../constants";

class Worktab extends Component {
    constructor(props) {
        super(props);
        this.state = {
          SelectedTab: '1'
        }
        this.toggle = this.toggle.bind(this);
      }
      toggle(tab) {
        if (this.state.SelectedTab !== tab) {
          this.setState({
            SelectedTab: tab
          });
        }
      }
    render() {
        return (
            <div className="row">
            <div className="col-12">
              <Nav tabs className="d-flex justify-content-center border-0">
                        <NavItem active>
                          <NavLink className={`border-0 `+ classnames({ active: this.state.SelectedTab === '1' })} onClick={() => { this.toggle('1'); }} >Building</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={`border-0 `+ classnames({ active: this.state.SelectedTab === '2' })} onClick={() => { this.toggle('2'); }}>Janitorial</NavLink>
                        </NavItem>
                        <NavItem disabled>
                          <NavLink className={`border-0 `+ classnames({ active: this.state.SelectedTab === '3' })} onClick={() => { this.toggle('3'); }}>Training</NavLink>
                        </NavItem>
                      </Nav>
              {/* <div className="tab-content mt-8" id="nav-tabContent"> */}
              <TabContent activeTab={this.state.SelectedTab} className="mt-8">
                <TabPane tabId="1" className="fade show" active>
                    <div className="row align-items-center justify-content-between mb-10">
                        <div>
                            <div className="row justify-content-center">
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="px-4 py-7 rounded hover-translate">
                                        <h5 className="mt-4 mb-3 text-center">Restoration of Granite, marble, concrete and wooden floors</h5>
                                        <p className="mb-0" style={{ textAlign: 'justify' }}>With advancements in technology, it has become cheaper and more convenient to repair, rather than replace damaged floors. While damaged ceramic and vitrified tiles are easily replaceable, more expensive floors like marble, granite and wooden ones can be conveniently repaired while still on the floor. Other floors finished in terrazzo, pvc and concrete can be subjected to thermoplastic treatment. Renent has the technical expertise to remove scratches from these luxurious stone surfaces and polish them with resin bond diamond disks back to gloss levels similar to those of newly laid stones</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                                    <div className="px-4 py-7 rounded hover-translate">
                                        <h5 className="mt-4 mb-3 text-center">One-off post construction cleaning of buildings</h5>
                                        <p className="mb-0 text-justify">This is a one time cleaning service to those moving into new homes, villas, shops or offices. We render this service at sites of newly constructed and renovated buildings.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="2" className="fade show" >
                  <div className="row align-items-center justify-content-between mb-10">
                      <div>
                          <div className="row justify-content-center">
                              <div className="col-xl-3 col-lg-4 mb-8 mb-lg-0">
                                  <div className="px-4 py-7 rounded hover-translate bg-primary-soft">
                                      <h5 className="mt-4 mb-3 text-center">Housekeeping Services</h5>
                                      <p className="mb-0 text-justify">
                                          Using the latest cleaning technology, we render specialist services in Industrial, commercial daily and periodic cleaning saving our clients time and money. A dedicated cleaning crew is assigned on monthly contractual basis to your office for daytime or overnight and in some cases both. Courtesy and attention to detail is our creed.
                                      </p>
                                  </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                                  <div className="px-4 py-7 rounded hover-translate">
                                      <h5 className="mt-4 mb-3 text-center">Cleaning of Private Dwellings such as Houses, Residential towers and Villas</h5>
                                      <p className="mb-0 text-justify">Our well-trained and trusted crew undertake detailed cleaning of your home or apartment building.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </TabPane>
                <TabPane tabId="3" className="fade show" >
                  <div className="row align-items-center justify-content-between mb-10">
                    <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                      <img src={require(`../../assets/images/svg/11.svg`)} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5">
                      <div>
                        <h4 className="mt-3">Training Academy</h4>
                        <p className="lead text-justify">Training programs run throughout the year and are flexible. They are tailored specifically to the needs of participants.</p>
                        <p className="mb-0 text-justify">
                            The center offers training in the following areas:
                            <ol>
                                <li>Basic Custodial Course: designed for corporate bodies, cleaning companies, and private individuals who want to train their staff or individuals who want to get trained as professional janitors.</li>
                                <li>Supervisors Course: Designed to equip participants with basic leadership and task achievement skills</li>
                                <li>Operations Officers Course: This course is designed specially for cleaning companies and cleaning companies who want to train their operators professionally on specialised cleaning, how to handle machines, and chemicals.</li>
                                <li>Manager’s Course: Equips participants with necessary skills to plan, organize, control the use of resources, review tasks and interact with clients.</li>
                                <li>Executive course: Designed for entrepreneurs who are interested in starting up a business in the janitorial services sector.</li>
                            </ol>
                        </p>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>

        );
    }
}

export default Worktab;
