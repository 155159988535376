import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faPersonSwimming, faMosquito  } from '@fortawesome/free-solid-svg-icons'
import { ICON_COLOUR } from "../../constants";

class Feature extends Component {
    render() {
        return (
            <section className="text-center p-0">
            <div className="container">
                <h2 className="mt-3">What we Offer</h2>
                <div className="row align-items-center">
                    <div className="col-xl-3 col-lg-4 mb-8 mb-lg-0">
                        <div className="px-4 py-7 rounded hover-translate bg-primary-soft">
                        <div>
                            <FontAwesomeIcon size="7x" icon={faClock} color={ICON_COLOUR} />
                        </div>
                        <h5 className="mt-4 mb-3">24/7 Response</h5>
                        <p className="mb-0">Anytime anywhere, and we mean it!</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="px-4 py-7 rounded hover-translate">
                        <div>
                           <FontAwesomeIcon icon={faBuilding} size="7x" color={ICON_COLOUR} />
                        </div>
                        <h5 className="mt-4 mb-3">Facility Maintenance</h5>
                        <p className="mb-0">Cleaning, Fumigation, Pest control and much more.</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                        <div className="px-4 py-7 rounded hover-translate">
                        <div>
                            <FontAwesomeIcon size="7x" icon={faPersonSwimming} color={ICON_COLOUR} />
                        </div>
                        <h5 className="mt-4 mb-3">Swimming Pool Maintenance</h5>
                        <p className="mb-0">Swimming pool cleaning and maintenance</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                        <div className="px-4 py-7 rounded hover-translate">
                            <div>
                                <FontAwesomeIcon size="7x" icon={faMosquito} color={ICON_COLOUR} />
                            </div>
                            <h5 className="mt-4 mb-3">Pest Control</h5>
                            <p className="mb-0">Fumigation and more to keep your building pest free.</p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        );
    }
}

export default Feature;
